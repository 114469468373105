exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-affiliate-js": () => import("./../../../src/pages/affiliate.js" /* webpackChunkName: "component---src-pages-affiliate-js" */),
  "component---src-pages-blog-dato-cms-post-slug-js": () => import("./../../../src/pages/blog/{DatoCmsPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-dato-cms-post-slug-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-terms-of-service-js": () => import("./../../../src/templates/terms-of-service.js" /* webpackChunkName: "component---src-templates-terms-of-service-js" */)
}

